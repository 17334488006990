import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Pages/login/login';
import { MainPage } from './Pages/mainPage';
import { Usuario } from './Pages/administrativo/usuario/usuario';
import { UsuarioIncluir } from './Pages/administrativo/usuario/usuarioIncluir';
import { UsuarioEditar } from './Pages/administrativo/usuario/usuarioEditar';
import { UsuarioVisualizar } from './Pages/administrativo/usuario/usuarioVisualizar';
import { CodigiodoSistema } from './Pages/administrativo/codigodoSistema/codigodosistema';
import { Empresa } from './Pages/administrativo/empresa/empresa';
import { EmpresaIncluir } from './Pages/administrativo/empresa/empresaIncluir';
import { EmpresaEditar } from './Pages/administrativo/empresa/empresaEditar';
import { EmpresaVisualizar } from './Pages/administrativo/empresa/empresaVisualizar';
import { CodigiodoSistemaIncluir } from './Pages/administrativo/codigodoSistema/codigodosistemaIncluir';
import { CodigiodoSistemaVisualizar } from './Pages/administrativo/codigodoSistema/codigodosistemaVisualizar';
import { CodigiodoSistemaEditar } from './Pages/administrativo/codigodoSistema/codigodosistemaEditar';
import { Filiais } from './Pages/administrativo/filial/filiais';
import { FilialIncluir } from './Pages/administrativo/filial/filialIncluir';
import { FilialEditar } from './Pages/administrativo/filial/filialEditar';
import { FilialVisualizar } from './Pages/administrativo/filial/filialVisualizar';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledEngineProvider } from "@mui/material/styles";
import MenuPrincipal from './Pages/menuPrincipal/menuPrincipal';
import MenuAdministrativo from './Pages/administrativo/menuAdministrativo';
import GridFilial from './Pages/administrativo/usuariosFilial/gridFilial'
import UsuariosFilial from './Pages/administrativo/usuariosFilial/usuariosFilial'
import Enderecar from './Pages/menuPrincipal/enderecar/enderecar';
import PagPrincipal from './Pages/menuPrincipal/conferenciaSaida/pagPrincipal';
import Equipamentos from './Pages/menuPrincipal/conferenciaSaida/equipamentos/equipamentos';
import CheckList from './Pages/menuPrincipal/conferenciaSaida/checkList/checkList';
import Desfragmentar from './Pages/menuPrincipal/desfragmentar/desfragmentar';
import LerVolume from './Pages/menuPrincipal/conferenciaSaida/lerVolume/lerVolume';
import LerGtin from './Pages/menuPrincipal/conferenciaSaida/lerGtin/lerGtin';
import MenuAcoes from './Pages/menuPrincipal/outrasAcoes/menuAcoes';
import PesagemUA from './Pages/menuPrincipal/conferenciaSaida/pesagemUA/pesagemUA';
import TelaPrincipalPicking from './Pages/menuPrincipal/picking/telaPrincipal';
import PedidoPicking from './Pages/menuPrincipal/picking/pedidoPicking';
import PagPrincipalEntrada from './Pages/menuPrincipal/conferenciaEntrada/pagPrincipalEnt';
import GerarImpressao from './Pages/menuPrincipal/conferenciaEntrada/gerarImpressao/gerarImpressao';
import { PerfilPermissoes } from './Pages/administrativo/perfilPermissao/perfilPermissoes';
import { PerfilPermissaoForm } from './Pages/administrativo/perfilPermissao/perfilPermissaoForm';
import MenuMonitoramento from './Pages/administrativo/painelMonitoramento/menuMonitoramento';
import GtinCargaHistorico from './Pages/administrativo/painelMonitoramento/components/gtinCargaHistorico';
import DadosGtinCargaAndamento from './Pages/administrativo/painelMonitoramento/components/gtinCargaAndamento';
import ProdutividadeGtin from './Pages/menuPrincipal/produtividadeGtin/produtividadeGtin';

function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route path="/" element={<MainPage />}>

            {/* Administrativo -> Usuário */}
            <Route path="administrativo/usuario" element={<Usuario />} />
            <Route path="administrativo/usuario/usuario/:id" element={<UsuarioIncluir />} />
            <Route path="administrativo/usuario/usuario/visu/:id" element={<UsuarioVisualizar />} />
            <Route path="administrativo/usuario/usuario/editar/:id" element={<UsuarioEditar />} />

            {/* Administrativo -> Permissão */}
            {/* <Route path="administrativo/permisao" element={<Permissao />} />
            <Route path="administrativo/permisao/permissao/:id" element={<PermissaoIncluir />} />
            <Route path="administrativo/permisao/permissao/visu/:id" element={<PermissaoVisualizar />} />
            <Route path="administrativo/permisao/permissao/editar/:id" element={<PermissaoEditar />} /> */}

            {/* Administrativo -> Empresa */}
            <Route path="administrativo/empresa" element={<Empresa />} />
            <Route path="administrativo/empresa/empresa/:id" element={<EmpresaIncluir />} />
            <Route path="administrativo/empresa/empresa/visu/:id" element={<EmpresaVisualizar />} />
            <Route path="administrativo/empresa/empresa/editar/:id" element={<EmpresaEditar />} />

            {/* Administrativo -> Códigos do Sistema */}
            <Route path="administrativo/codistema" element={<CodigiodoSistema />} />
            <Route path="administrativo/codistema/codsis/:id" element={<CodigiodoSistemaIncluir />} />
            <Route path="administrativo/codistema/codsis/visu/:id" element={<CodigiodoSistemaVisualizar />} />
            <Route path="administrativo/codistema/codsis/editar/:id" element={<CodigiodoSistemaEditar />} />

            {/* Cadastro de permissoes */}
            <Route path="config/segur/cadastroDePermissoes" element={<PerfilPermissoes />} />
            <Route path="config/segur/cadastroDePermissoes/:acao/:id/:id_filial" element={<PerfilPermissaoForm />} />

            {/*Administrativo -> Cadastro -> Filial */}
            <Route path="cadastro/filiais/filial" element={<Filiais />} />
            <Route path="cadastro/filiais/filials/:id" element={<FilialIncluir />} />
            <Route path="cadastro/filiais/filial/:id" element={<FilialIncluir />} />
            <Route path="cadastro/filiais/filial/visu/:id" element={<FilialVisualizar />} />
            <Route path="cadastro/filiais/filial/editar/:id" element={<FilialEditar />} />

            {/* Menu principal*/}
            <Route path="menu/menuPrincipal" element={<MenuPrincipal />} />

            {/* Menu Administrativo*/}
            <Route path="menu/menuAdministrativo" element={<MenuAdministrativo />} />

            {/* Usuarios/Filial*/}
            <Route path="grid/filial" element={<GridFilial />} />
            <Route path="usuarios/filial/:idFilial" element={<UsuariosFilial />} />

            {/* Enderecar*/}
            <Route path="menu/enderecar" element={<Enderecar />} />

            {/* Página Principal da conferencia de saída */}
            <Route path='conferencia/saida' element={<PagPrincipal />} />

            {/* Equipamentos, página dentro da conferencia de saída */}
            <Route path='conferencia/saida/equipamentos/:processo' element={<Equipamentos />} />

            {/* CheckList, página dentro da conferencia de */}
            <Route path='conferencia/checkList/:tipoConferencia' element={<CheckList />} />

            {/* Desfragmentar*/}
            <Route path='menu/desfragmentar' element={<Desfragmentar />} />

            {/* Ler Volumes*/}
            <Route path='conferencia/lerVolumes/:OS/:PROCESSO' element={<LerVolume />} />

            {/* Ler Volumes*/}
            <Route path='conferencia/lerGtin/:OS/:processo' element={<LerGtin />} />

            <Route path='acoes/menuAcoes' element={<MenuAcoes />} />

            <Route path='conferencia/pesagemUA/:OS' element={<PesagemUA />} />

            <Route path='menu/picking' element={<TelaPrincipalPicking />} />

            <Route path='picking/pedidos/:pedido' element={<PedidoPicking />} />

            <Route path='conferencia/entrada' element={<PagPrincipalEntrada />} />

            <Route path='conferencia/entrada/gerarImpressao/:processo' element={<GerarImpressao />} />

            <Route path='administrativo/painelMonitoramento' element={<MenuMonitoramento/>}/>
            <Route path='administrativo/painelMonitoramento/gtinCargaHistorico' element={<GtinCargaHistorico/>}/>  
            <Route path='administrativo/painelMonitoramento/dados/gtin/:token/:idFilial' element={<DadosGtinCargaAndamento/>}/>  
            <Route path='menu/produtividadeGtin/:tipoUsuario' element={<ProdutividadeGtin/>}/>
        
          </Route>

          <Route path="/login" element={<Login />} />
        </Routes>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
